import * as React from 'react'
import { motion } from 'motion/react'
// styles
import './styles.css'
// constants
import { dropdownItems } from '../../constants'
// components
import Item from './Item'

const DropList = () => (
  <motion.div
    className='dropdown-wrapper width-100'
    initial='hidden'
    whileInView='visible'
    viewport={{ once: true, amount: 0.5 }}
    variants={{
      hidden: { opacity: 0 },
      visible: {
        opacity: 1,
        y: 0,
        transition: {
          duration: 2,
          ease: 'easeInOut',
        },
      },
    }}
  >
    <span className='description font-bold'>Key benefits include:</span>
    <div className='margin-t-2'>
      {dropdownItems.map((item) => (
        <Item key={item.label} item={item} />
      ))}
    </div>
  </motion.div>
)

export default DropList
