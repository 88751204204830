import * as React from 'react'
import './styles.css'
import ReCAPTCHA from 'react-google-recaptcha'
import { useForm } from 'react-hook-form'
import { RECAPTCHA_SITE_KEY } from '../../../constants/env'
import Input from '../../ui/Input/input'
import { BACKEND_URL } from '../../../constants/env'

const ContactComponent = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm()

  const [captchaValue, setCaptchaValue] = React.useState(null)
  const [captchaError, setCaptchaError] = React.useState(false)
  const [submitError, setSubmitError] = React.useState(false)
  const [submitSuccess, setSubmitSuccess] = React.useState(false)

  const onSubmit = async (data) => {
    if (!captchaValue) {
      setCaptchaError(true)
      return
    }

    const requestBody = {
      data: {
        email: data.email,
        phone: data.phone,
        message: data.message,
        lastName: data.lastName,
        firstName: data.firstName,
      },
      token: captchaValue,
    }

    try {
      const response = await fetch(`${BACKEND_URL}/api/contacts/send`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody),
      })

      if (!response.ok) {
        setSubmitError(true)
        return
      }
      setSubmitSuccess(true)
    } catch (error) {
      setSubmitError(true)
    }
  }

  const handleCaptchaChange = (value) => {
    setCaptchaValue(value)
    setCaptchaError(false)
  }

  return (
    <section className='contact-section'>
      <div className='contact-container'>
        <h2 className='main-title'>Contact Us</h2>
        <form className='contact-form' onSubmit={handleSubmit(onSubmit)}>
          <div className='form-row'>
            <Input
              id='firstName'
              type='text'
              required={true}
              label='First Name'
              register={register}
              errors={errors}
              watch={watch}
            />
            <Input
              id='lastName'
              type='text'
              required={true}
              label='Last Name'
              register={register}
              errors={errors}
              watch={watch}
            />
          </div>

          <div className='form-row'>
            <Input
              id='phone'
              type='text'
              required={false}
              label='Phone Number'
              register={register}
              errors={errors}
              watch={watch}
            />
            <Input
              id='email'
              type='email'
              required={true}
              label='Email'
              register={register}
              errors={errors}
              watch={watch}
            />
          </div>

          <Input
            id='message'
            type='text'
            required={true}
            label='Message'
            register={register}
            errors={errors}
            watch={watch}
          />

          {RECAPTCHA_SITE_KEY && (
            <div className='recaptcha'>
              <ReCAPTCHA
                sitekey={RECAPTCHA_SITE_KEY}
                theme='dark'
                onChange={handleCaptchaChange}
              />
              {captchaError && (
                <p className='recaptcha-error-message'>
                  Please complete the reCAPTCHA
                </p>
              )}
            </div>
          )}

          <div>
            {submitError && (
              <p className='submit-message submit-error'>
                There was an error submitting your form. Please try again.
              </p>
            )}
            {submitSuccess && (
              <p className='submit-message submit-success'>
                Thank you for getting in touch.
              </p>
            )}
          </div>

          <button type='submit' className='submit-button'>
            Submit
          </button>
        </form>
      </div>
    </section>
  )
}

export default ContactComponent
