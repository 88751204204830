import * as React from 'react'
import { motion, AnimatePresence } from 'motion/react'
// constants
import { testimonials } from '../../../../../constants/hero'
// styles
import './styles.css'
// libs
import classNames from 'classnames'

const HeroCarousel = () => {
  const [activeIdx, setActiveIdx] = React.useState(0)
  const isFirstRender = React.useRef(true)

  React.useEffect(() => {
    const timer = setTimeout(() => {
      isFirstRender.current = false
    }, 1000)

    const interval = setInterval(() => {
      const nextIdx = (activeIdx + 1) % testimonials.length
      setActiveIdx(nextIdx)
    }, 8500)

    return () => {
      clearInterval(interval)
      clearTimeout(timer)
    }
  }, [activeIdx])

  const currentItem = testimonials[activeIdx]

  return (
    <>
      <AnimatePresence mode='wait'>
        <motion.div
          key={activeIdx}
          initial={{ opacity: 0, x: 50 }}
          animate={{ opacity: 1, x: 0 }}
          exit={{ opacity: 0, x: -50 }}
          transition={{ duration: 1.5 }}
          className='hero-card'
        >
          {currentItem.images ? (
            <div>
              <motion.p
                className='hero-user-quote'
                initial={
                  isFirstRender.current
                    ? { opacity: 0, y: 15 }
                    : { opacity: 1, x: 0 }
                }
                animate={{ opacity: 1, y: 0, x: 0 }}
                transition={{
                  duration: isFirstRender.current ? 1 : 0,
                }}
              >
                {currentItem.text}
              </motion.p>
              <div className='card-images'>
                {currentItem.images.map((img, idx) => (
                  <motion.img
                    key={idx}
                    src={img.src}
                    alt={img.alt}
                    initial={
                      isFirstRender.current
                        ? { opacity: 0, y: 15 }
                        : { opacity: 1, x: 0 }
                    }
                    animate={{ opacity: 1, y: 0, x: 0 }}
                    transition={{
                      duration: isFirstRender.current ? 1 : 0,
                      delay: isFirstRender.current ? 0.5 + idx * 0.2 : 0,
                    }}
                  />
                ))}
              </div>
            </div>
          ) : (
            <div>
              <div>
                <p className='hero-user-quote'>{currentItem.quote}</p>
              </div>
              <div className='hero-feedback'>
                <img src={currentItem.avatar} alt={currentItem.alt} />
                <div>
                  <p className='hero-feedback-name'>{currentItem.name}</p>
                  <p className='hero-feedback-position'>
                    {currentItem.position}
                  </p>
                </div>
              </div>
            </div>
          )}
        </motion.div>
      </AnimatePresence>
      <div className='dots-container'>
        {testimonials.map((_, idx) => (
          <span
            key={idx}
            className={classNames('dot', { active: idx === activeIdx })}
            onClick={() => setActiveIdx(idx)}
          />
        ))}
      </div>
    </>
  )
}

export default HeroCarousel
