import * as React from 'react'
import classNames from 'classnames'
import Logo from '../../../assets/imgs/logo.svg'
import './styles.css'

const Header = () => {
  const [open, setOpen] = React.useState(false)

  React.useLayoutEffect(() => {
    const updateSize = () => {
      if (window.innerWidth > 750 && open) {
        setOpen(false)
      }
    }

    window.addEventListener('resize', updateSize)

    return () => window.removeEventListener('resize', updateSize)
  }, [open])

  const burgerClassName = classNames('burger', { open: open })
  const navClassName = classNames('menu', { open: open })

  React.useEffect(() => {
    const removeHash = () => {
      if (window.location.hash) {
        setTimeout(() => {
          window.history.replaceState(null, '', window.location.pathname)
        }, 500)
      }
    }

    window.addEventListener('hashchange', removeHash, false)

    return () => {
      window.removeEventListener('hashchange', removeHash)
    }
  }, [])

  return (
    <header>
      <div className='box'>
        <div className='header-content'>
          <a href='/'>
            <img src={Logo} alt='Logo' />
          </a>
          <div className='nav-menu'>
            <button className={burgerClassName} onClick={() => setOpen(!open)}>
              <div className='line' />
              <div className='line' />
              <div className='line' />
            </button>
            <nav className={navClassName}>
              <a href='/#overview' onClick={() => setOpen(false)}>
                Overview
              </a>
              <a href='/#news' onClick={() => setOpen(false)}>
                News
              </a>
              <a href='/#team' onClick={() => setOpen(false)}>
                Team
              </a>
              <a
                href='/contact'
                onClick={() => setOpen(false)}
              >
                Contact Us
              </a>
            </nav>
            <div className='menu-desktop'>
              <a href='/#overview'>Overview</a>
              <a href='/#news'>News</a>
              <a href='/#team'>Team</a>
              <a href='/contact'>Contact Us</a>
            </div>
          </div>
        </div>
      </div>
    </header>
  )
}

export default Header
