import * as React from 'react'
import { motion } from 'motion/react'
// static
import Map1 from '../../../../../assets/imgs/map/map1-min.png'
import Map2 from '../../../../../assets/imgs/map/map2-min.png'
import Map3 from '../../../../../assets/imgs/map/map3-min.png'
import Map4 from '../../../../../assets/imgs/map/map4-min.png'
import Map5 from '../../../../../assets/imgs/map/map5-min.png'
import Map6 from '../../../../..//assets/imgs/map/map6-min.png'
// styles
import './styles.css'

const slides = [Map1, Map2, Map3, Map4, Map5, Map6]

const Carousel = () => {
  const [currentIndex, setCurrentIndex] = React.useState(0)
  const [previousIndex, setPreviousIndex] = React.useState(slides.length - 1)

  React.useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => {
        setPreviousIndex(prevIndex)
        return (prevIndex + 1) % slides.length
      })
    }, 4000)

    return () => clearInterval(interval)
  }, [])

  return (
    <div className='span-5 padding-r-5 carousel carousel-elements'>
      <img
        src={Map1}
        className='elements hid-img'
        alt='Default map background'
        style={{
          opacity: 0,
        }}
      />
      {slides.map((slide, index) => (
        <motion.img
          key={index}
          src={slide}
          className='carousel-slide elements'
          alt={`Map ${index + 1}`}
          animate={{
            opacity: index === currentIndex || index === previousIndex ? 1 : 0,
            zIndex: index === currentIndex || index === previousIndex ? 1 : 0,
          }}
          transition={{
            opacity: {
              duration: 2,
              ease: 'easeInOut',
            },
          }}
        />
      ))}
    </div>
  )
}

export default Carousel
