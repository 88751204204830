// static
import { ReactComponent as StartCards } from '../../../assets/imgs/overview/start.svg'
import { ReactComponent as TalentPoolAndDecisionCards } from '../../../assets/imgs/overview/talentPool.svg'
import { ReactComponent as EmployeeExperience } from '../../../assets/imgs/overview/employeeExperience.svg'
import { ReactComponent as Support } from '../../../assets/imgs/overview/support.svg'
import { ReactComponent as Chart } from '../../../assets/imgs/overview/chart.svg'
// styles
import './styles.css'
// constants
import { overviewSectionItems } from './constants'
import {
  descriptionAnimation,
  titleAnimation,
} from '../../../constants/animation'
// components
import DropList from './components/DropList'
import Carousel from './components/Carousel'
import AnimatedContainer from '../../ui/AnimatedContainer/animatedContainer'
// hooks
import { useStartCardsAnimation } from '../../../hooks/animationHomeMotion'
//libs
import * as React from 'react'
import { motion } from 'motion/react'

const Overview = ({ scrollRef }) => {
  const {
    start,
    talentPool,
    decision,
    workforceAnalysis,
    futureWorkForce,
    employeeExperience,
    support,
    chart,
    security,
  } = overviewSectionItems

  const {
    overviewRef,
    talentRef,
    employeeRef,
    gearRef,
    chartRef,
    employeeContainerRef,
  } = useStartCardsAnimation()

  return (
    <section ref={scrollRef} className='overview-section'>
      <div className='box'>
        <div className='overview-grid'>
          <div id='start' className='grid-10 padding-top-10'>
            <StartCards
              ref={overviewRef}
              className='elements span-6 padding-r-5'
            />
            <AnimatedContainer className='applications-content overview span-4 padding-l-5 primary-row'>
              <motion.h2 className='main-title' variants={titleAnimation}>
                {start.title}
              </motion.h2>
              <motion.p className='description' variants={descriptionAnimation}>
                {start.description}
              </motion.p>
            </AnimatedContainer>
          </div>

          <div id='talent' className='grid-10 padding-top-10'>
            <AnimatedContainer className='applications-content span-4 flex-column'>
              <AnimatedContainer>
                <motion.span
                  className='overview-title talent'
                  variants={titleAnimation}
                >
                  {talentPool.title}
                </motion.span>
                <motion.p
                  className='description margin-b-5 talent-text'
                  variants={descriptionAnimation}
                >
                  {talentPool.description}
                </motion.p>
              </AnimatedContainer>
              <AnimatedContainer>
                <motion.span
                  className='overview-title decision'
                  variants={titleAnimation}
                >
                  {decision.title}
                </motion.span>
                <motion.p
                  className='description decision-text'
                  variants={descriptionAnimation}
                >
                  {decision.description}
                </motion.p>
              </AnimatedContainer>
            </AnimatedContainer>

            <TalentPoolAndDecisionCards
              ref={talentRef}
              className='elements span-6'
            />
          </div>

          <div id='heat-map' className='grid-10 padding-top-20'>
            <Carousel />
            <AnimatedContainer className='applications-content span-5 padding-l-5 primary-row'>
              <motion.span
                className='overview-title heat-mapping'
                variants={titleAnimation}
              >
                {workforceAnalysis.title}
              </motion.span>
              <motion.p className='description' variants={descriptionAnimation}>
                {workforceAnalysis.description}
              </motion.p>
              <DropList />
            </AnimatedContainer>
          </div>

          <div id='future-force' className='span-12 padding-top-10'>
            <AnimatedContainer>
              <motion.span
                className='overview-title heat-mapping'
                variants={titleAnimation}
              >
                {futureWorkForce.title}
              </motion.span>
              <motion.p className='description' variants={descriptionAnimation}>
                {futureWorkForce.description}
              </motion.p>
            </AnimatedContainer>
          </div>

          <div id='employee-experience' className='grid-10'>
            <AnimatedContainer className='employee-experience span-5 padding-r-5'>
              <motion.span
                className='overview-title heat-mapping'
                variants={titleAnimation}
              >
                {employeeExperience.title}
              </motion.span>
              <motion.p className='description' variants={descriptionAnimation}>
                {employeeExperience.description}
              </motion.p>
            </AnimatedContainer>
            <div ref={employeeContainerRef} className='span-5'>
              <EmployeeExperience ref={employeeRef} className='elements' />
            </div>
          </div>

          <div id='support' className='grid-10 padding-top-4'>
            <div className='span-5 padding-r-5'>
              <Support ref={gearRef} className='elements' />
            </div>
            <AnimatedContainer className='span-5 padding-l-5 primary-row'>
              <motion.span
                className='overview-title heat-mapping'
                variants={titleAnimation}
              >
                {support.title}
              </motion.span>
              <motion.p className='description' variants={descriptionAnimation}>
                {support.description}
              </motion.p>
            </AnimatedContainer>
          </div>

          <div id='chart' className='grid-10'>
            <AnimatedContainer className='span-4'>
              <AnimatedContainer>
                <motion.span
                  className='overview-title heat-mapping'
                  variants={titleAnimation}
                >
                  {chart.title}
                </motion.span>
                <motion.p
                  className='description'
                  variants={descriptionAnimation}
                >
                  {chart.description}
                </motion.p>
              </AnimatedContainer>
              <AnimatedContainer>
                <motion.span
                  className='overview-title heat-mapping'
                  variants={titleAnimation}
                >
                  {security.title}
                </motion.span>
                <motion.p
                  className='description'
                  variants={descriptionAnimation}
                >
                  {security.description}
                </motion.p>
              </AnimatedContainer>
            </AnimatedContainer>
            <div className='span-6 padding-l-5'>
              <Chart ref={chartRef} className='elements' />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Overview
